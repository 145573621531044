export const CrossSVG = () => {
  return (
    <div className="wrapper">
      <svg
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.50777 0.994391L12.9998 9.48639L21.4478 1.03839C21.6344 0.839769 21.8592 0.680879 22.1087 0.571248C22.3582 0.461616 22.6273 0.403502 22.8998 0.400391C23.4832 0.400391 24.0428 0.632176 24.4554 1.04476C24.868 1.45734 25.0998 2.01691 25.0998 2.60039C25.1049 2.87012 25.0549 3.13804 24.9527 3.38773C24.8506 3.63742 24.6985 3.8636 24.5058 4.05239L15.9478 12.5004L24.5058 21.0584C24.8684 21.4131 25.081 21.8935 25.0998 22.4004C25.0998 22.9839 24.868 23.5434 24.4554 23.956C24.0428 24.3686 23.4832 24.6004 22.8998 24.6004C22.6194 24.612 22.3397 24.5652 22.0783 24.463C21.817 24.3607 21.5798 24.2052 21.3818 24.0064L12.9998 15.5144L4.52977 23.9844C4.34388 24.1764 4.12181 24.3297 3.87638 24.4354C3.63094 24.5411 3.36699 24.5972 3.09977 24.6004C2.5163 24.6004 1.95672 24.3686 1.54414 23.956C1.13156 23.5434 0.899772 22.9839 0.899772 22.4004C0.894643 22.1307 0.944686 21.8627 1.04683 21.6131C1.14898 21.3634 1.30107 21.1372 1.49377 20.9484L10.0518 12.5004L1.49377 3.94239C1.13118 3.58766 0.918559 3.1073 0.899772 2.60039C0.899772 2.01691 1.13156 1.45734 1.54414 1.04476C1.95672 0.632176 2.5163 0.400391 3.09977 0.400391C3.62777 0.406991 4.13377 0.620391 4.50777 0.994391Z"
          fill="#E64742"
        />
      </svg>
    </div>
  );
};
