import { Collapse, Tooltip } from "reactstrap";
import clx from "classnames";
import React from "react";
import { ellipseAddress } from "../../utils";
import styles from "./index.module.scss";
import { CollapseSkeleton } from "../Skeleton";
import { useStore } from "../../store";
import { DARK_THEME, LIGHT_THEME } from "../../constants";
import { useState } from "react";
import { logger } from "frontend-utils/logger";

/********************************************** COLLAPSE **********************************************/

interface CustomCollapseProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onClick?: (() => void) | null;
  hasToggler?: boolean;
  skeletonCss?: string;
  toggleCss?: string | null;
  togglerName?: string | null;
}

export const CustomCollapse = ({
  children,
  isOpen = false,
  onClick = null,
  hasToggler = false,
  skeletonCss = "",
  toggleCss = null,
  togglerName = null,
}: CustomCollapseProps) => {
  const loading = useStore((state) => state.loading);
  const appTheme = useStore((state) => state.appTheme);

  const [localToggle, setLocalToggle] = useState(false);
  const toggleDetails = () => setLocalToggle((prev) => !prev);
  if (loading)
    // This is the skeleton which appears in place of the toggle accordion. If there is no toggler, it should not be displayed
    return hasToggler ? <CollapseSkeleton wrapperCss={skeletonCss} /> : null;
  return (
    <>
      {hasToggler && (
        <Toggler
          toggleCss={toggleCss}
          onClick={onClick || toggleDetails}
          isOpen={isOpen || localToggle}
          appTheme={appTheme || LIGHT_THEME}
          togglerName={togglerName}
        />
      )}
      <Collapse style={{ width: "100%" }} isOpen={isOpen || localToggle}>
        {children}
      </Collapse>
    </>
  );
};

/********************************************** COLLAPSE TOGGLER **********************************************/

interface TogglerProps {
  isOpen: boolean;
  onClick: () => void;
  appTheme: string;
  toggleCss?: string | null;
  togglerName?: string | null;
}

export const Toggler = ({
  isOpen,
  onClick,
  appTheme,
  toggleCss,
  togglerName,
}: TogglerProps) => {
  return (
    <div
      onClick={onClick}
      className={clx(
        styles.toggler,
        isOpen ? styles.is_open : "",
        appTheme === DARK_THEME && styles.dark_mode_toggler,
        toggleCss && toggleCss
      )}
      id="details_toggler"
    >
      {togglerName || "Details"}
      <img
        src="/images/chev.svg"
        alt=""
        className={isOpen ? styles.flip : ""}
      />
    </div>
  );
};

/********************************************** DETAILS ROW WITH LABEL AND VALUE **********************************************/

export enum ELLIPSE_TYPE {
  // Do not ellipse the text
  // Text can wrap to the next line
  NONE = "NONE",

  // Ellipse the text by adding ... at the end
  TEXT = "TEXT",

  // Ellipse the address by adding ... in the middle
  ADDRESS = "ADDRESS",
}

interface DetailsRowProps {
  id: string; // A unique id for the row

  // LABEL
  label?: string; // Label of the row
  labelCss?: string; // CSS for the label

  // VALUE
  value: string; // Value of the row
  valueCss?: string; // CSS for the value

  ellipseType?: ELLIPSE_TYPE; // Type of ellipse to be applied

  wrapperCss?: string; // Wrapper css for the row
}

export const DetailsRow = ({
  label,
  value,
  id,
  ellipseType = ELLIPSE_TYPE.NONE,
  wrapperCss = "",
  valueCss = "",
  labelCss = "",
}: DetailsRowProps) => {
  const appTheme = useStore((state) => state.appTheme);
  return (
    <div
      className={clx(
        styles.details_row,
        appTheme === DARK_THEME && styles.dark_mode_details_row,
        wrapperCss && wrapperCss
      )}
    >
      {label && (
        <div className={clx(styles.details_label, labelCss)}>{label}</div>
      )}
      <ValueItem
        valueCss={clx(valueCss, !label && styles.no_label)}
        value={value as string}
        id={id}
        ellipseType={ellipseType}
      />
    </div>
  );
};

/********************************************** DETAILS ROW VALUE **********************************************/

interface ValueItemProps {
  value: string;
  id: string;

  ellipseType: ELLIPSE_TYPE;
  valueCss?: string;
}

export const ValueItem = ({
  value,
  id,
  ellipseType,
  valueCss,
}: ValueItemProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <>
      <Tooltip className="value-tooltip" isOpen={isCopied} target={id}>
        Copied
      </Tooltip>
      <div
        id={id}
        className={clx(
          styles.value_item,
          valueCss && valueCss,
          ellipseType === ELLIPSE_TYPE.TEXT && styles.ellipse_text
        )}
        onClick={handleCopy}
      >
        {ellipseType === ELLIPSE_TYPE.ADDRESS
          ? ellipseAddress(value, 7)
          : `${value}`}
      </div>
    </>
  );
};
