import React from "react";
import {
  CLOSE_MODAL_COPY,
  ERROR,
  REQUEST_CANCELLED,
  USER_CONSENT_DENIED,
  USER_REQUEST_DENIED,
} from "../../constants";
import { useStore } from "../../store";
import clx from "classnames";
import { postMessage, getButtonBg } from "../../utils";
import { CustomButton } from "../CustomButton";
import { FRAME_CSS_SELECTOR } from "../../utils/cssUtils";
import { WIDGET_TYPE } from "../../store/widgetSlice";

const FRAME_STATE_MAP: Record<WIDGET_TYPE, string> = {
  [WIDGET_TYPE.CONSENT]: USER_REQUEST_DENIED,
  [WIDGET_TYPE.APPROVAL]: REQUEST_CANCELLED,
  [WIDGET_TYPE.SIGN]: REQUEST_CANCELLED,
  [WIDGET_TYPE.LOGIN]: REQUEST_CANCELLED,
  [WIDGET_TYPE.PREFETCH]: REQUEST_CANCELLED,
};

const FRAME_MSG_MAP: Record<WIDGET_TYPE, string> = {
  [WIDGET_TYPE.CONSENT]: USER_CONSENT_DENIED,
  [WIDGET_TYPE.APPROVAL]: USER_REQUEST_DENIED,
  [WIDGET_TYPE.SIGN]: USER_REQUEST_DENIED,
  [WIDGET_TYPE.LOGIN]: USER_REQUEST_DENIED,
  [WIDGET_TYPE.PREFETCH]: USER_REQUEST_DENIED,
};

export const CustomModal: React.FC = () => {
  const {
    secondaryColor,
    setCloseModalState,
    setAuthModalState,
    setFrameState,
    skipFinalScreen = false,
    appTheme,
    isMobileApp,
    customCss,
    widgetSlice,
  } = useStore((state) => state);
  const btnBg = getButtonBg(appTheme!, secondaryColor!);

  const onConfirm = () => {
    setCloseModalState(false);
    setAuthModalState(false);
    const response = {
      status: widgetSlice.widgetType
        ? FRAME_MSG_MAP[widgetSlice.widgetType]
        : USER_REQUEST_DENIED,
    };
    postMessage(ERROR, response, skipFinalScreen);
    setFrameState({
      state: widgetSlice.widgetType
        ? FRAME_STATE_MAP[widgetSlice.widgetType]
        : REQUEST_CANCELLED,
      frameResponse: response,
    });
  };

  const onCancel = () => {
    setCloseModalState(false);
  };

  const frameContainerCss = customCss![FRAME_CSS_SELECTOR] || {};
  const customBackgroundColor = frameContainerCss["background-color"];
  const customBorderRadius = frameContainerCss["border-radius"];

  const customBorderRadiusStyle = customBorderRadius
    ? { borderRadius: customBorderRadius }
    : {};
  const customContainerStyle = {
    ...(customBackgroundColor
      ? { backgroundColor: customBackgroundColor }
      : {}),
    ...customBorderRadiusStyle,
  };

  return (
    <div className={clx("custom-overlay", isMobileApp && "mobile-overlay")}>
      <div className="custom-modal" style={customContainerStyle}>
        <div className="custom-modal-copy">{CLOSE_MODAL_COPY}</div>
        <div className="custom-modal-cta">
          <CustomButton
            onClick={onConfirm}
            className="primary-btn clr-transp round"
            style={{ borderColor: btnBg, color: btnBg }}
            id="yes_btn"
          >
            Yes
          </CustomButton>
          <CustomButton
            style={{
              background: btnBg,
              borderColor: btnBg,
            }}
            onClick={onCancel}
            className="primary-btn round"
            id="no_btn"
          >
            No
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
