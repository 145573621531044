import clx from "classnames";
import { useStore } from "../../store";
import styles from "./index.module.scss";
import { FRAME_CSS_SELECTOR } from "../../utils/cssUtils";
import { useScreenSize } from "../../hooks";
import { TABLET_WIDTH_BREAKPOINT } from "../../constants";
export const Banner = () => {
  const loading = useStore((state) => state.loading);
  const isMobileApp = useStore((state) => state.isMobileApp);
  const customCss = useStore((state) => state.customCss);
  const { width: windowWidth } = useScreenSize();
  const frameContainerCss = customCss[FRAME_CSS_SELECTOR] || {};
  const customBorderRadius = frameContainerCss["border-radius"];

  // for mobile devices the i.e assuming window width < 500px border radius is always 0px
  // for cases where screen size is greater than 500px we apply the custom border radius
  // this covers tablets and desktops
  const applyCustomBorderRadius =
    !isMobileApp || windowWidth >= TABLET_WIDTH_BREAKPOINT;
  const cutomBorderStyle = {
    ...(customBorderRadius && applyCustomBorderRadius
      ? {
          borderRadius: getTopBorderRadiusValues(
            customBorderRadius,
            isMobileApp
          ),
        }
      : {}),
  };
  if (loading) return null;
  return (
    <div
      className={clx(styles.banner, isMobileApp && styles.is_mobile)}
      style={cutomBorderStyle}
    >
      <div className={styles.banner_text}>This website is not approved</div>
    </div>
  );
};

const getTopBorderRadiusValues = (borderRadiusValue = "", isMobileApp) => {
  const values = borderRadiusValue.split(" ");
  const defaultValue = isMobileApp ? "0px" : "18.75px";
  const topLeft = values[0] || defaultValue;
  const topRight = values[1] || topLeft;
  return [topLeft, topRight, 0, 0].join(" ");
};
