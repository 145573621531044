import React from "react";
import ContentLoader from "react-content-loader";
import { useStore } from "../../store";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";

interface InvokeDetailSkeletonProps {
  wrapperCss: string;
}

export const InvokeDetailSkeleton: React.FC<InvokeDetailSkeletonProps> = ({
  wrapperCss = "",
}) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const width = isMobileApp ? "290" : "262";
  const height = isMobileApp ? "290" : "268";
  const viewBox = `0 0 ${width} ${height}`;
  const appTheme = useStore((state) => state.appTheme);

  const bgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG;
  const fgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG;
  return (
    <div className={wrapperCss}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={viewBox}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        <rect x="1" y="1" rx="10" ry="10" width={width} height={height} />
      </ContentLoader>
    </div>
  );
};
