import React from "react";
import clx from "classnames";
import { Input } from "reactstrap";
import { useStore } from "../../store";
import { InputSkeleton } from "../Skeleton";

/**
 * @author: nadeem@passbird.co
 * A Custom Text Input of given type
 * @param {string} type Type of input text, password
 * @param {function} onChangeHandler function to handle onChange event
 * @param {string} wrapperCss custom css for wrapper element
 * @param {string} inputCss custom css for input element
 * @param {string} placeholder placeholder text for input
 * @param {string} label label for the input element
 * @param {string} id input element id
 * @param {string} error error msg for invalid input
 * @returns React Input Component
 */

interface CustomInputProps {
  type?: string;
  onChangeHandler: (e: any) => void;
  wrapperCss?: string;
  inputCss?: string;
  placeholder?: string;
  label?: string;
  id: string;
  error?: string | null;
  value?: string;
  disabled?: boolean;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  type = "text",
  onChangeHandler,
  wrapperCss,
  inputCss,
  placeholder = "",
  label,
  id,
  error = null,
  value,
  disabled = false,
  ...rest
}) => {
  const loading = useStore((state) => state.loading);
  if (loading) return <InputSkeleton />;
  return (
    <div className={clx("input-wrapper", wrapperCss && wrapperCss)}>
      {label && <label htmlFor={id}>{label}</label>}
      <Input
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        type={type as any}
        onChange={onChangeHandler}
        className={clx("text-input", inputCss && inputCss, error && "error")}
        value={value || ""}
        {...rest}
      />
      {error && <span>{error}</span>}
    </div>
  );
};
