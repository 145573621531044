import React, { useMemo } from "react";
import ContentLoader from "react-content-loader";
import { useStore } from "../../store";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";

interface OTPSkeletonProps {
  wrapperCss: string;
}

export const OTPSkeleton: React.FC<OTPSkeletonProps> = ({ wrapperCss }) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const width = isMobileApp ? "320" : "262";
  const viewBox = `0 0 ${width} 42`;
  const appTheme = useStore((state) => state.appTheme);

  const bgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG;
  const fgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG;

  const rectArr = useMemo(() => {
    const rWidth = isMobileApp ? 41 : 36;
    const delta = isMobileApp ? 55 : 45;
    const rects = [];
    for (let i = 0; i < 6; i++) {
      rects.push(
        <rect
          key={i}
          x={i === 0 ? 1 : i * delta}
          y="1"
          rx="8"
          ry="8"
          width={rWidth}
          height="41"
        />
      );
    }
    return rects;
  }, [isMobileApp]);

  return (
    <div className={wrapperCss}>
      <ContentLoader
        speed={2}
        width={320}
        height={42}
        viewBox={viewBox}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        {rectArr}
      </ContentLoader>
    </div>
  );
};
