export const CheckSVG = () => {
  return (
    <svg
      width="173"
      height="142"
      viewBox="0 0 173 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="scale-in-center"
    >
      <g clipPath="url(#clip0_256_92)">
        <circle cx="92.2539" cy="70.4336" r="27.3867" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.6875 71.875C44.6875 59.6419 49.5471 47.9098 58.1972 39.2597C66.8473 30.6096 78.5794 25.75 90.8125 25.75C103.046 25.75 114.778 30.6096 123.428 39.2597C132.078 47.9098 136.938 59.6419 136.938 71.875C136.938 84.1081 132.078 95.8402 123.428 104.49C114.778 113.14 103.046 118 90.8125 118C78.5794 118 66.8473 113.14 58.1972 104.49C49.5471 95.8402 44.6875 84.1081 44.6875 71.875ZM88.1803 91.6165L114.736 58.4188L109.939 54.5812L87.2947 82.8773L71.2555 69.5134L67.3195 74.2366L88.1803 91.6227V91.6165Z"
          fill="#73BE4B"
        />
        <path
          d="M135.062 30.25L146.688 27.625L152.688 11.125L159.062 7.375"
          stroke="#DCDCDC"
          strokeWidth="0.75"
        />
      </g>
      <circle
        className="delayed-fade-in"
        cx="92.3125"
        cy="132.625"
        r="1.875"
        fill="#73BD4A"
      />
      <circle
        className="delayed-fade-in"
        cx="45.4375"
        cy="123.25"
        r="4.5"
        fill="#73BD4A"
      />
      <circle
        className="delayed-fade-in"
        cx="106.938"
        cy="137.5"
        r="4.5"
        fill="#23A4BC"
      />
      <circle
        className="delayed-fade-in"
        cx="148.938"
        cy="67"
        r="4.5"
        fill="#77D3FC"
      />
      <circle
        className="delayed-fade-in"
        cx="34.5625"
        cy="51.625"
        r="4.875"
        fill="#F7C16A"
      />
      <circle
        className="delayed-fade-in"
        cx="90.8125"
        cy="14.125"
        r="2.625"
        fill="#F7C16A"
      />
      <circle
        className="delayed-fade-in"
        cx="77.3125"
        cy="5.125"
        r="4.875"
        fill="#C063FB"
      />
      <circle
        className="delayed-fade-in"
        cx="161.125"
        cy="69.625"
        r="1.875"
        fill="#FF8FA7"
      />
      <path
        d="M147.25 30.25L152.028 29.2364C156.684 28.2489 160.382 24.7152 161.579 20.1092L164.174 10.13C164.386 9.31228 164.84 8.57747 165.476 8.02112V8.02112C166.71 6.94117 168.459 6.67628 169.958 7.34235L170.875 7.75"
        stroke="#FA5586"
        strokeWidth="3"
        strokeLinecap="round"
        className="delayed-fade-in"
      />
      <path
        d="M143.5 106.75L146.705 109.154C151.187 112.515 153.422 118.094 152.501 123.62L151.75 128.125"
        stroke="#F7C16A"
        strokeWidth="3"
        strokeLinecap="round"
        className="delayed-fade-in"
      />
      <path
        d="M32.1533 12.8293L29.1808 16.3518C24.5693 21.8164 24.4561 29.7764 28.9104 35.3699L29.0364 35.5282"
        stroke="#23A4BC"
        strokeWidth="3"
        strokeLinecap="round"
        className="delayed-fade-in"
      />
      <path
        d="M1.9375 101.5L7.0559 103.953C13.8588 107.212 21.9724 103.699 24.2497 96.5074L25.9212 91.2291C26.5149 89.3542 25.2627 87.4 23.3112 87.1561V87.1561C20.1458 86.7604 18.468 90.7829 20.9764 92.7537L23.6881 94.8844C27.7562 98.0808 33.751 95.5369 34.2789 90.3903L34.5625 87.625"
        stroke="#77D3FC"
        strokeWidth="3"
        strokeLinecap="round"
        className="delayed-fade-in"
      />
      <defs>
        <clipPath id="clip0_256_92">
          <rect
            width="92.25"
            height="92.25"
            fill="white"
            transform="translate(44.6875 25.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
