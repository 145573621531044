import React from "react";
import { useRef, useState } from "react";
import clx from "classnames";

import { CustomCollapse, DetailsRow, ELLIPSE_TYPE } from "../DetailsCollapse";
import { ellipseAddress } from "../../utils";
import styles from "./index.module.scss";
import { useStore } from "../../store";
import { InvokeDetailSkeleton } from "../Skeleton";
import { DARK_THEME } from "../../constants";
import { PLACEHOLDER_IMAGE_URL, useImage } from "../../hooks/useImage";
import { ParsedUser } from "frontend-utils";
import { logger } from "frontend-utils/logger";

export const NftItem = () => {
  const imgRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const consentData = useStore((state) => state.consentData);

  const loading = useStore((state) => state.loading);
  const isMobileApp = useStore((state) => state.isMobileApp);
  const fromUser = useStore((state) => state.user);
  const appTheme = useStore((state) => state.appTheme);

  const { onImageError, onImageLoad } = useImage(imgRef);

  if (!consentData) {
    logger.log("NftItem: consentData is null");
    return null;
  }

  if (!fromUser) {
    logger.log("NftItem: fromUser is null");
    return null;
  }

  const {
    tokenMetadata: metadata,
    toDetails,
    nftCollection,
    nftTokenId,
  } = consentData;

  const toggleDetails = () => setOpen((prev) => !prev);

  const { image = null, backgroundColor = null, name = null } = metadata as any;

  const getTokenNameString = (str: string) => {
    if (typeof str !== "string") return "-";
    if (str.length > 21) return ellipseAddress(str, 7);
    return str;
  };

  if (loading) return <InvokeDetailSkeleton wrapperCss={styles.nft_skelly} />;

  return (
    <div
      className={clx(
        styles.nft_item_container,
        isMobileApp && styles.in_mobile_cont,
        appTheme === DARK_THEME && styles.dark_mode_nft_item_container
      )}
    >
      <div
        className={clx(
          styles.nft_item_img,
          isMobileApp && styles.in_mobile_img
        )}
        style={{
          backgroundColor: backgroundColor ? `#${backgroundColor}` : "",
        }}
      >
        <img
          ref={imgRef}
          src={image ? image : PLACEHOLDER_IMAGE_URL}
          onError={onImageError}
          onLoad={onImageLoad}
          alt=""
        />
      </div>
      <div className={styles.nft_details}>
        <div className={styles.nft_token_id}>
          {getTokenNameString(name || nftTokenId)}
        </div>
        <div
          id="details_toggler"
          className={clx(styles.details_toggle, isOpen && styles.open)}
          onClick={toggleDetails}
        >
          {isOpen ? "Hide" : "Details"}
        </div>
      </div>
      <CustomCollapse
        isOpen={isOpen}
        children={
          <NFTDetails
            fromUser={fromUser}
            toDetails={toDetails}
            nftCollection={nftCollection || ""}
            nftTokenId={nftTokenId || ""}
          />
        }
      />
    </div>
  );
};

interface NFTDetailsProps {
  fromUser: ParsedUser;
  toDetails: any;
  nftCollection: string;
  nftTokenId: string;
}

const NFTDetails: React.FC<NFTDetailsProps> = ({
  fromUser,
  toDetails,
  nftCollection,
  nftTokenId,
}) => {
  const toAddress =
    toDetails?.email ||
    toDetails?.ethAddress ||
    toDetails?.address ||
    toDetails?.solAddress ||
    "-";
  const ifEllipseNeededForTokenId = nftTokenId?.length > 20 ? true : false;
  const userIdentifier = fromUser.displayString;

  return (
    <div className={styles.nft_details_container}>
      <hr />
      {nftCollection && (
        <DetailsRow
          label="Collection"
          value={nftCollection}
          id="nftCollection"
          ellipseType={ELLIPSE_TYPE.ADDRESS}
        />
      )}
      <DetailsRow label="From" value={userIdentifier} id="fromUser" />
      <DetailsRow label="To" value={toAddress} id="fromUser" />
      <DetailsRow
        label="Token"
        value={nftTokenId}
        id="nftTokenId"
        ellipseType={
          ifEllipseNeededForTokenId ? ELLIPSE_TYPE.ADDRESS : ELLIPSE_TYPE.NONE
        }
      />
    </div>
  );
};
