import React from "react";
import ContentLoader from "react-content-loader";
import clx from "classnames";
import { useStore } from "../../store";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";

interface SkeletonProps {
  single?: boolean;
  wrapperCss?: string;
}
export const TextSkeleton: React.FC<SkeletonProps> = ({
  single = false,
  wrapperCss,
}) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const lgWidth = isMobileApp ? "290" : "214";
  const smWidth = isMobileApp ? "200" : "162";
  const appTheme = useStore((state) => state.appTheme);

  const bgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG;
  const fgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG;
  return (
    <div className={clx("text-skeleton", wrapperCss)}>
      <ContentLoader
        speed={2}
        width={lgWidth}
        height={16}
        viewBox={`0 0 ${lgWidth} 16`}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        <rect x="0" y="0" rx="6" ry="6" width={lgWidth} height="16" />
      </ContentLoader>
      {!single && (
        <>
          <ContentLoader
            speed={2}
            width={smWidth}
            height={16}
            viewBox={`0 0 ${smWidth} 16`}
            backgroundColor={bgColor}
            foregroundColor={fgColor}
          >
            <rect x="0" y="0" rx="6" ry="6" width={smWidth} height="16" />
          </ContentLoader>
        </>
      )}
    </div>
  );
};
