import { useEffect, useRef } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { useStore, TOAST_ID } from "../../store";
import clx from "classnames";
import { FRAME_CSS_SELECTOR } from "../../utils/cssUtils";
import { useScreenSize } from "../../hooks";
import { TABLET_WIDTH_BREAKPOINT } from "../../constants";
// Make sure to tweak the animation duration in the css file
// when this value changes
// css Toastify__toast-theme--dark
const TIMEOUT_DELAY = 4000;
// callback execution timeout
const EXEC_DELAY = 200;

const Fade = cssTransition({
  enter: "fadeInDown",
  exit: "fadeInDown",
});

// TODO: Add a toast type prop to this component
// Handle Action Type And Message only toast

const CloseIcon = ({ closeToast }) => {
  const { setSnackbarState } = useStore((state) => state);
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (timeoutRef.current === null) {
      timeoutRef.current = setTimeout(() => {
        // update toast message
        toast.update(TOAST_ID, {
          render: <PostProgressContent />,
          autoClose: false,
          closeButton: false,
          hideProgressBar: true,
        });
        timeoutRef.current = null;
      }, TIMEOUT_DELAY);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);
  const onCloseClick = () => {
    setSnackbarState({ open: false, onCompleteCb: null });
    closeToast();
  };
  return (
    <div onClick={onCloseClick} className="toast-close-icon">
      <span>Undo</span>
    </div>
  );
};

export const SnackBar = () => {
  const { snackbarState, setSnackbarState, customCss, isMobileApp } = useStore(
    (state) => state
  );
  const { open, toastOpts = {} } = snackbarState;
  const {
    autoClosein = null, // set to auto close toast after x ms
    hideProgress = false, // hide progress bar
    hideBg = false, // hide toast bg blur
  } = toastOpts;
  const timeoutRef = useRef(null);
  const { width: windowWidth } = useScreenSize();
  useEffect(() => {
    if (
      autoClosein &&
      typeof autoClosein === "number" &&
      timeoutRef.current === null
    ) {
      timeoutRef.current = setTimeout(() => {
        toast.dismiss(TOAST_ID);
        setSnackbarState({ open: false, onCompleteCb: null, toastOpts: {} });
        timeoutRef.current = null;
      }, autoClosein);
    }
    return () => {
      if (timeoutRef && timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [autoClosein, setSnackbarState]);
  const frameContainerCss = customCss[FRAME_CSS_SELECTOR] || {};
  const customBorderRadius = frameContainerCss["border-radius"];

  // for mobile devices the i.e assuming window width < 500px border radius is always 0px
  // for cases where screen size is greater than 500px we apply the custom border radius
  // this covers tablets and desktops
  const applyCustomBorderRadius =
    !isMobileApp || windowWidth >= TABLET_WIDTH_BREAKPOINT;
  const customWrapperBgStyle = {
    ...(customBorderRadius && applyCustomBorderRadius
      ? { borderRadius: customBorderRadius }
      : {}),
  };
  if (!open) return null;

  return (
    <div className="snackbar-wrapper">
      <div
        className={clx(
          "wrapper-bg",
          hideProgress && "no-progress",
          hideBg && "hide-bg"
        )}
        style={customWrapperBgStyle}
      >
        <ToastContainer
          position="bottom-center"
          theme="dark"
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          limit={1}
          closeButton={<CloseIcon />}
          hideProgressBar={true}
          closeOnClick={false}
          draggable={false}
          autoClose={false}
          transition={Fade}
        />
      </div>
    </div>
  );
};

const SuccessSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8ZM7.54347 11.424L12.1493 5.66613L11.3173 5.00053L7.38987 9.90827L4.608 7.5904L3.92533 8.4096L7.54347 11.4251V11.424Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

const PostProgressContent = () => {
  const { snackbarState, setSnackbarState } = useStore((state) => state);
  const callbackTimerRef = useRef(null);
  const { onCompleteCb } = snackbarState;
  useEffect(() => {
    if (callbackTimerRef?.current === null) {
      // execute callback after 1.5s
      callbackTimerRef.current = setTimeout(() => {
        onCompleteCb();
        toast.dismiss(TOAST_ID);
        setSnackbarState({ open: false, onCompleteCb: null });
        callbackTimerRef.current = null;
      }, EXEC_DELAY);
    }
    return () => {
      if (callbackTimerRef.current) clearTimeout(callbackTimerRef.current);
    };
  }, [onCompleteCb, setSnackbarState]);
  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>Submitted</span>
      <SuccessSVG />
    </div>
  );
};
