import React, { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";
import { MetakeepAuth as Auth, SessionKeysUtils } from "frontend-utils";
import { OTPField } from "..";
import { validator, saveUserApprovalToStorage } from "../../utils";
import styles from "./index.module.scss";
import { useStore } from "../../store";
import { TextSkeleton } from "../Skeleton";
import clx from "classnames";
import { logger } from "frontend-utils/logger";
import { storageWrapper } from "../../utils/storageHelper";
import {
  SHOW_RESEND_AFTER,
  SKIP_RECOVERY_TIMEOUT_KEY,
  RECOVERY_EMAIL_ENABLED,
  DARK_THEME,
  NETWORK_STATES,
  TOO_MANY_LOGIN_REQUESTS,
  MAX_FAILED_LOGIN_ATTEMPTS,
  AUTH_FAILED,
} from "../../constants";
import Lottie from "react-lottie";

import * as fingerprintDark from "./fingerprint-lottie/fingerprint-dark.json";
import * as fingerprintLight from "./fingerprint-lottie/fingerprint-light.json";
import { useSessionKeys } from "./hooks/useSessionKeys";
import { AxiosMetaKeepError } from "../../utils/error";

const { SESSION_KEY_TYPES } = SessionKeysUtils;

/**
 * @author nadeem@passbird.co
 * @param {function} callback - runs after code input
 * @param {string} email email address
 * @returns React component
 */
const DEFAULT_ERR_MSG = "Something went wrong. Please try again";
const CODE_EXPIRED_MSG =
  "Verification failed. You may close this popup and try again";
const INVALID_CODE = "Invalid code";
const INVALID_CHALLENGE_ANSWER = "INVALID_CHALLENGE_ANSWER";
const INVALID_CODE_MSG = "The code you've entered is incorrect";

export const SCREEN_NAMES = Object.freeze({
  OTP: "OTP",
  PASSKEY: "PASSKEY",
});

const buildErrMessage = (errStr: string) => {
  if (!errStr || typeof errStr !== "string") return DEFAULT_ERR_MSG;
  if (errStr === INVALID_CODE) return INVALID_CODE_MSG;
  if (errStr === INVALID_CHALLENGE_ANSWER) return INVALID_CODE_MSG;
  if (
    errStr === "Incorrect username or password." ||
    errStr.includes("Invalid session") ||
    // For Metakeep auth
    errStr === TOO_MANY_LOGIN_REQUESTS ||
    errStr === MAX_FAILED_LOGIN_ATTEMPTS ||
    errStr === AUTH_FAILED
  )
    return CODE_EXPIRED_MSG;
  return DEFAULT_ERR_MSG;
};

// We make several API calls to start the sign-in process.
// Since we show the OTP input in advance, user might enter the code when the sign-in request is still pending.
// This method checks the sign-in network state and allows us to wait
// for sign-in request to begin before making code verification request
//
// This is a recursive function,
// If the network state is idle or failed, end and resolve or reject based on the network state
// If the network state is pending, wait and retry.
const waitForSignInToBegin = async () => {
  const networkState = useStore.getState().signinRequestNetworkState;

  return new Promise<void>((resolve, reject) => {
    if (networkState === NETWORK_STATES.IDLE) return resolve();
    if (networkState === NETWORK_STATES.FAILED) return reject(DEFAULT_ERR_MSG);
    // If the network state is in pending call again.
    // Running this in timeout prevents call stack error
    const timeout = setTimeout(() => {
      if (networkState === NETWORK_STATES.PENDING) waitForSignInToBegin();
      clearTimeout(timeout);
    }, 1000);
  });
};

interface VerifyUserProps {
  callback: () => void;
}

export const VerifyUser = ({ callback }: VerifyUserProps) => {
  const [authErr, setAuthErr] = useState("");
  const sessionId = useStore((state) => state.sessionId);
  const setLoading = useStore((state) => state.setLoading);
  const domain = useStore((state) => state.requesterDomain);
  const isMobileApp = useStore((state) => state.isMobileApp);
  const toggleRecoveryEmailWizard = useStore(
    (state) => state.toggleRecoveryEmailWizard
  );
  const user = useStore((state) => state.user);
  const setAuthModalState = useStore((state) => state.setAuthModalState);
  const appTheme = useStore((state) => state.appTheme);
  const appId = useStore((state) => state.appId);

  const [endAuth, setEndAuth] = useState(false);
  const [recoveryEmailUsed, setRecoveryEmailUsed] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const [currentScreen, setCurrentScreen] = useState<keyof typeof SCREEN_NAMES>(
    SCREEN_NAMES.OTP
  );

  const keyPairs: MutableRefObject<SessionKeysUtils.SessionKey[] | null> =
    useRef(null);

  const { generateKeys, saveKeysToSessionStorage } = useSessionKeys({
    setCurrentScreen,
  } as any);

  // Show a loader briefly to simulate a network call.
  // We do this because the API calls can be slow and
  // a long loader causes poor user experience.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
      clearTimeout(timeout);
    }, 700);
  }, [setLoading]);

  const setAuthErrMsg = (err: string, reset = false) => {
    if (reset) {
      setAuthErr("");
      return;
    }
    const errorMsg = buildErrMessage(err);
    if (errorMsg && errorMsg !== INVALID_CODE_MSG) setEndAuth(true);

    setAuthErr(errorMsg);
  };

  const postCompleteAuth = async (
    askForRecoveryEmail: boolean,
    keyPairs: SessionKeysUtils.SessionKey[]
  ) => {
    saveUserApprovalToStorage(domain!, appId!); // set user approval in local storage
    try {
      // Save a sessionTableKey of user email and aws client id, this way we can support multiple
      // aws user pools without having to sign out the user
      const sessionId = (await Auth.currentSession())?.getIdToken()?.payload
        .origin_jti;

      logger.log("postCompleteAuth: ", sessionId);
      await saveKeysToSessionStorage({ keyPairs, sessionId: sessionId! });
    } catch (err) {
      // if we fail to store keys to db, we should sign out
      logger.log("Failed to store keys to db", err);
      Auth.signOut();
      const { message = "" } = err as Error;
      setAuthErrMsg(message);
      throw new Error(message);
    }
    const timeoutKey = `${SKIP_RECOVERY_TIMEOUT_KEY}_${user?.displayString}`;
    const todayEpochMillis = Date.now();
    const timeoutPeriod = Number(
      storageWrapper.getItem(timeoutKey) || todayEpochMillis
    );
    const canAskForRecoveryEmail = timeoutPeriod <= todayEpochMillis;

    if (
      RECOVERY_EMAIL_ENABLED &&
      askForRecoveryEmail &&
      canAskForRecoveryEmail
    ) {
      logger.log("postCompleteAuth: ", "Asking for recovery email");

      toggleRecoveryEmailWizard(true);
      setAuthModalState(false);
    } else if (callback && typeof callback === "function") {
      logger.log(
        "postCompleteAuth: ",
        "Not asking for recovery email and calling callback"
      );

      await callback();
    }
  };

  const onVerify = async (
    keyPairs: SessionKeysUtils.SessionKey[] | null = null,
    code: string
  ) => {
    const { code: err } = validator("code", code);
    if (err) return;
    try {
      logger.log("onVerify: ", "Verifying code");

      setLoading(true);
      await waitForSignInToBegin();

      const webauthnKey = keyPairs?.find(
        (key) => key.keyType === SESSION_KEY_TYPES.WEBAUTHN
      ) as SessionKeysUtils.WebauthnSessionKey;
      const cryptoKey = keyPairs?.find(
        (key) => key.keyType === SESSION_KEY_TYPES.CRYPTO
      ) as SessionKeysUtils.WebCryptoSessionKey;

      const sessionId = useStore.getState().sessionId;

      const { askForRecoveryEmail } = (await Auth.sendCustomChallengeAnswer({
        sessionId: sessionId!,
        verifyOtp: {
          otp: code,
          webAuthnRegistrationCredential: webauthnKey
            ? webauthnKey.webAuthnRegistrationCredential
            : undefined,
          sessionKey: {
            publicKey: cryptoKey?.publicKey!,
            keyType: SESSION_KEY_TYPES.CRYPTO,
          },
        },
      })) as {
        askForRecoveryEmail: boolean;
      };

      await postCompleteAuth(askForRecoveryEmail, keyPairs!);
      return;
    } catch (err) {
      logger.log("onVerify: ", "Failed to verify code", err);

      setAuthErrMsg((err as AxiosMetaKeepError)?.response?.data?.status || "");
    } finally {
      setLoading(false);
    }
  };
  const handleGenerateKeyPairAndVerify = async (code: string) => {
    // If key pair already exists, for example after the user entered invalid otp, it should use the same key pair.
    if (keyPairs.current) {
      onVerify(keyPairs.current, code);
      return;
    }
    try {
      generateKeys({
        onSuccess: (newKeyPairs) => {
          keyPairs.current = newKeyPairs;
          onVerify(newKeyPairs, code);
        },
      });
    } catch (error) {
      logger.error("Failed to generate keys:", error);
    }
  };

  const handleCodeChange = async (code: string) => {
    setAuthErr("");
    if (code.length === 6) {
      handleGenerateKeyPairAndVerify(code);
      return;
    }
  };

  const onUseRecoveryEmail = async () => {
    let recoveryEmail = "";
    try {
      setLoading(true);
      const response: any = await Auth.sendCustomChallengeAnswer({
        sessionId: sessionId!,
        useRecoveryEmail: {},
      });

      recoveryEmail = response?.maskedRecoveryEmail;

      setRecoveryEmail(recoveryEmail);
      setRecoveryEmailUsed(true);
      setAuthErrMsg("", true);
    } catch (err) {
      setAuthErrMsg((err as AxiosMetaKeepError)?.response?.data?.status || "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={clx(
        styles.auth_content,
        isMobileApp && styles.is_mobile_app,
        appTheme === DARK_THEME && styles.dark_mode
      )}
    >
      {currentScreen === SCREEN_NAMES.OTP && (
        <>
          <div
            className={clx(
              styles.auth_copy,
              isMobileApp && styles.is_mobile_app
            )}
          >
            <AuthCopy
              recoveryEmailUsed={recoveryEmailUsed}
              recoveryEmail={recoveryEmail}
            />
          </div>
          <OTPField
            isDisabled={endAuth}
            onChangeHandler={handleCodeChange}
            hasError={authErr as any} // TODO: Fix this
          />
          {endAuth ? null : (
            <ResendCodeText
              setErrMsg={setAuthErrMsg}
              recoveryEmailUsed={recoveryEmailUsed}
            />
          )}
          <UseRecoveryEmail
            isUsed={recoveryEmailUsed}
            onUseRecoveryEmail={onUseRecoveryEmail}
            authEnded={endAuth}
          />
        </>
      )}

      {currentScreen === SCREEN_NAMES.PASSKEY && (
        <div className={styles.passkey}>
          <span>Let's use screen lock to further secure your account</span>
          <div className={styles.passkey_svg_container}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData:
                  appTheme === DARK_THEME ? fingerprintDark : fingerprintLight,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={200}
              width={200}
              speed={2}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface UseRecoveryEmailProps {
  isUsed: boolean;
  onUseRecoveryEmail: () => void;
  authEnded: boolean;
}

const UseRecoveryEmail = ({
  isUsed,
  onUseRecoveryEmail,
  authEnded,
}: UseRecoveryEmailProps) => {
  const recoveryOptionsAvailable = useStore(
    (state) => state.recoveryOptionsAvailable
  );
  const loading = useStore((state) => state.loading);
  if (!recoveryOptionsAvailable || loading || isUsed || authEnded) return null;
  return (
    <div className={clx(styles.use_recovery_email_wrapper)}>
      To use your recovery email{" "}
      <span onClick={onUseRecoveryEmail}>Click here</span>
    </div>
  );
};

interface AuthCopyProps {
  recoveryEmail: string;
  recoveryEmailUsed: boolean;
}

const AuthCopy = ({ recoveryEmail, recoveryEmailUsed }: AuthCopyProps) => {
  const user = useStore((state) => state.user);
  const loading = useStore((state) => state.loading);
  if (loading)
    return (
      <>
        <TextSkeleton />
        <TextSkeleton single wrapperCss={styles.single_text_skelly} />
      </>
    );
  const copy = recoveryEmailUsed ? (
    `We have sent a security code to ${recoveryEmail}`
  ) : (
    <>
      For extra security, we have sent a code to{" "}
      <span style={{ whiteSpace: "nowrap" }}>{user?.displayString}</span>
    </>
  );
  return (
    <>
      {copy}

      <div className={styles.auth_sub}>Please enter it below</div>
    </>
  );
};

interface ResendCodeTextProps {
  setErrMsg: (err: string, reset?: boolean) => void;
  recoveryEmailUsed?: boolean;
}

const ResendCodeText = ({
  setErrMsg,
  recoveryEmailUsed = false,
}: ResendCodeTextProps) => {
  const [resendConfig, setResendConfig] = useState({
    enableResend: false,
    codeSent: false,
  });
  const { loading, setSnackbarState } = useStore((state) => state);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(Number(SHOW_RESEND_AFTER));
  const setLoading = useStore((state) => state.setLoading);
  const sessionId = useStore((state) => state.sessionId);

  useEffect(() => {
    if (timeLeft > 0) {
      intervalRef.current = setTimeout(() => {
        setTimeLeft((prevState) => prevState - 1);
      }, 1000);
    } else {
      setResendConfig((prevState) => ({ ...prevState, enableResend: true }));
    }

    return () => {
      if (intervalRef && intervalRef.current) clearTimeout(intervalRef.current);
    };
  }, [timeLeft]);

  const { enableResend, codeSent } = resendConfig;

  const handleResend = async () => {
    if (!enableResend || codeSent) return;
    try {
      setLoading(true);
      await Auth.sendCustomChallengeAnswer({
        sessionId: sessionId!,
        resendOtp: { useRecoveryEmail: recoveryEmailUsed },
      });
      setErrMsg("", true);
      setResendConfig((prevState) => ({ ...prevState, codeSent: true }));
      setSnackbarState({
        open: true,
        message: "Verification code has been re-sent!",
        toastOpts: {
          autoClosein: 2000,
          hideProgress: true,
          hideBg: true,
          closeButton: false,
        },
      });
    } catch (err) {
      setErrMsg((err as AxiosMetaKeepError)?.response?.data?.status || "");
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className={styles.resend_sub}>
        <TextSkeleton wrapperCss={styles.retry_skelly} />
      </div>
    );
  return (
    <div className={styles.resend_sub}>
      <span>
        If the email is in the ‘Spam’ or other folders, please move it to
        ‘Inbox’ for security.
        {codeSent ? null : (
          <>
            {" "}
            You may request to{" "}
            <span
              onClick={handleResend}
              className={clx(
                styles.resend_text,
                enableResend && !codeSent && styles.enabled,
                codeSent && styles.code_sent
              )}
            >
              Resend
            </span>{" "}
            code{" "}
          </>
        )}
        {timeLeft > 0 ? (
          <span className={styles.timer_wrap}>in {timeLeft}s</span>
        ) : null}
      </span>
    </div>
  );
};
