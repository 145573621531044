import React, { useState, useEffect, useCallback, useRef } from "react";
import clx from "classnames";
import { shallow } from "zustand/shallow";

import { CustomInput } from "../CustomInput";
import { useStore } from "../../store";
import { TextSkeleton, InputSkeleton } from "../Skeleton";
import { CustomButton } from "../CustomButton";
import styles from "./index.module.scss";
import PhoneInput, { Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ParsedUser, USER_TYPE, User } from "frontend-utils";
import { logger } from "frontend-utils/logger";
import { AUTH_PROVIDER_STATE } from "../../store/appSlice";
import { CountryCode } from "libphonenumber-js/min";

interface UserDetailsProps {
  onSubmit: () => void;
}

const INVALID_USER_ERROR: Partial<Record<USER_TYPE, string>> = {
  [USER_TYPE.TYPE_EMAIL]: "Please enter a valid email address",
  [USER_TYPE.TYPE_PHONE]: "Please enter a valid phone number",
};

export const UserDetails = ({ onSubmit }: UserDetailsProps) => {
  const userInStore = useStore((state) => state.user, shallow);
  const { setUser, userWallet, loading } = useStore(
    (state) => ({
      setUser: state.setUser,
      userWallet: state.userWallet,
      loading: state.loading,
    }),
    shallow
  );

  const [selectedUserType, setSelectedUserType] = useState<USER_TYPE>(
    USER_TYPE.TYPE_EMAIL
  );
  const [enteredUser, setEnteredUser] = useState<User | null>(null);
  const [enteredUserErr, setEnteredUserErr] = useState("");

  // This is a flag to indicate if the user has been successfully entered and state updated
  const [done, setDone] = useState(false);

  // This is a flag to indicate if the onSubmit callback has been called to prevent multiple calls
  // in case of re-renders and potential bugs.
  const onSubmitCalledRef = useRef(false);

  logger.debug("UserDetails: running");

  // Trigger the onSubmit callback when the user has been set in the store
  useEffect(() => {
    if (onSubmitCalledRef.current) {
      logger.error(
        "UserDetails: onSubmit has already been called, skipping calling again"
      );
      return;
    }

    if (done) {
      logger.debug("UserDetails: done flag is set, calling onSubmit");
      onSubmitCalledRef.current = true;
      onSubmit();
    }
  }, [done, onSubmit]);

  const switchUserType = useCallback(
    (type: USER_TYPE) => {
      // Email is always allowed. Phone is allowed if the dev has phone auth enabled
      const allowedUserTypes = [USER_TYPE.TYPE_EMAIL] as USER_TYPE[];
      if (
        userWallet?.authProviders?.phone?.state === AUTH_PROVIDER_STATE.ENABLED
      ) {
        allowedUserTypes.push(USER_TYPE.TYPE_PHONE);
      }

      logger.debug("UserDetails: switchUserType", type);

      if (!allowedUserTypes.includes(type)) {
        logger.debug(
          "UserDetails: switchUserType: user type not allowed",
          type
        );
        return;
      }

      setSelectedUserType(type);
      setEnteredUserErr("");
    },
    [userWallet]
  );

  // If the user in the store changes, we will reset the userType in the component
  // Note that this should not be visible to user, since it should happen when the frame data is loaded
  useEffect(() => {
    if (!userInStore) {
      logger.debug("UserDetails: userInStore is null");
      return;
    }

    if (userInStore.type) {
      logger.debug(
        "UserDetails: valid user in store, switching user type",
        userInStore
      );
      switchUserType(userInStore.type);
    }
  }, [userInStore, switchUserType]);

  // Reset error message when entered user changes
  useEffect(() => {
    setEnteredUserErr("");
  }, [enteredUser]);

  const onButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!enteredUser) {
      logger.debug("UserDetails: onButtonClick: No user entered");
      setEnteredUserErr(
        INVALID_USER_ERROR[selectedUserType] || "Please enter a valid user"
      );
      return;
    }

    // Try parsing the entered user
    let parsedUser: ParsedUser;
    try {
      parsedUser = new ParsedUser(enteredUser);
    } catch (error) {
      setEnteredUserErr(
        INVALID_USER_ERROR[selectedUserType] || "Please enter a valid user"
      );
      return;
    }

    // We do not call onSubmit here, since we want to wait for the user to be set in the store
    // So we set the entered user and the flag to indicate that the user has been set
    // and then trigger the onSubmit callback in the useEffect hook
    logger.debug("UserDetails: onButtonClick: parsedUser", parsedUser);
    setUser(parsedUser);
    setDone(true);
  };

  return (
    <>
      <form onSubmit={onButtonClick}>
        <div className="frame-copy">
          <UserDetailIntentText userType={selectedUserType} />
        </div>
        <div className="frame-input">
          {selectedUserType === USER_TYPE.TYPE_EMAIL && (
            <EmailInput
              setEnteredUser={setEnteredUser}
              enteredUserErr={enteredUserErr}
            />
          )}
          {selectedUserType === USER_TYPE.TYPE_PHONE && (
            <PhoneEnter
              setEnteredUser={setEnteredUser}
              enteredUserErr={enteredUserErr}
            />
          )}
        </div>
        {
          // Only show if phone auth is allowed and the user is not loading
          userWallet?.authProviders?.phone?.state ===
            AUTH_PROVIDER_STATE.ENABLED &&
            !loading && (
              <div
                id="use-phone-email-toggle"
                onClick={() => {
                  switchUserType(
                    selectedUserType === USER_TYPE.TYPE_EMAIL
                      ? USER_TYPE.TYPE_PHONE
                      : USER_TYPE.TYPE_EMAIL
                  );
                }}
                className={styles.use_phone}
              >
                {selectedUserType === USER_TYPE.TYPE_EMAIL
                  ? "use your phone instead"
                  : "use your email instead"}
              </div>
            )
        }
        <div className="frame-cta">
          <CustomButton
            id="continue-btn"
            type="submit" // Ensure this button triggers form submission
            className="primary-btn lg round"
            skeletonCss={styles.usr_btn_skeleton}
          >
            Continue
          </CustomButton>
        </div>
      </form>
    </>
  );
};

interface UserInputProps {
  setEnteredUser: (user: User) => void;
  enteredUserErr: string;
}

const EmailInput = ({ setEnteredUser, enteredUserErr }: UserInputProps) => {
  const userInStore = useStore((state) => state.user, shallow);
  const userProvided = useStore((state) => state.userProvided);

  const [enteredEmail, setEnteredEmail] = useState(
    userInStore?.rawUser?.email || ""
  );

  logger.debug("EmailInput: running");

  // If the user in the store changes, we will reset the email in the component
  useEffect(() => {
    if (!userInStore) {
      logger.debug("EmailInput: userInStore is null");
      return;
    }

    if (userInStore?.rawUser?.email) {
      logger.debug(
        "EmailInput: valid email in store",
        userInStore.rawUser.email
      );
      setEnteredEmail(userInStore.rawUser.email);
      setEnteredUser({
        email: userInStore.rawUser.email,
      });
    }
  }, [userInStore, setEnteredUser, setEnteredEmail]);

  // handle email field change
  const onEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmail(ev.target.value);
    setEnteredUser({
      email: ev.target.value,
    });
  };

  return (
    <CustomInput
      id="email"
      placeholder="Enter your email address"
      value={enteredEmail}
      type="email"
      onChangeHandler={onEmailChange}
      error={enteredUserErr}
      data-testid="email-input"
      disabled={userProvided}
    />
  );
};

const PhoneEnter = ({ setEnteredUser, enteredUserErr }: UserInputProps) => {
  const userInStore = useStore((state) => state.user, shallow);
  const loading = useStore((state) => state.loading);
  const userWallet = useStore((state) => state.userWallet, shallow);
  const listOfEnabledCountries =
    userWallet?.authProviders?.phone?.enabledCountries;

  const [enteredPhone, setEnteredPhone] = useState(
    userInStore?.rawUser?.phone || ""
  );

  logger.debug("PhoneEnter: running");

  // If the user in the store changes, we will reset the phone in the component
  useEffect(() => {
    if (!userInStore) {
      logger.debug("PhoneEnter: userInStore is null");
      return;
    }

    if (userInStore?.rawUser?.phone) {
      setEnteredPhone(userInStore.rawUser.phone);
      setEnteredUser({
        phone: userInStore.rawUser.phone,
      });
    }
  }, [userInStore, setEnteredUser, setEnteredPhone]);

  if (loading) return <InputSkeleton wrapperCss="" />;

  const onPhoneChange = (value: Value) => {
    setEnteredPhone(value as string);
    setEnteredUser({
      phone: value as string,
    });
  };

  return (
    <div className={clx("input-wrapper")}>
      <PhoneInput
        id="phone-input"
        countries={listOfEnabledCountries as CountryCode[]}
        international={true}
        addInternationalOption={false}
        withCountryCallingCode={true}
        defaultCountry="US"
        placeholder="Enter your phone number"
        containerComponentProps={{
          className: clx("text-input", enteredUserErr && "error"),
          style: { display: "flex", alignItems: "center" },
        }}
        onChange={onPhoneChange}
        value={enteredPhone as Value}
        numberInputProps={{ className: "phone-input" }}
      ></PhoneInput>
      {enteredUserErr && <span>{enteredUserErr}</span>}
    </div>
  );
};

interface UserDetailIntentTextProps {
  userType: USER_TYPE;
}

const UserDetailIntentText: React.FC<UserDetailIntentTextProps> = ({
  userType,
}) => {
  const { displayName, loading } = useStore(
    (state) => ({ displayName: state.displayName, loading: state.loading }),
    shallow
  );
  if (loading) return <TextSkeleton />;

  return (
    <p>
      {userType === USER_TYPE.TYPE_PHONE &&
        `${displayName} needs your phone number to continue`}
      {userType === USER_TYPE.TYPE_EMAIL &&
        `${displayName} needs your email address to continue`}
    </p>
  );
};
