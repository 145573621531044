import { CLOSE_FRAME } from "../../constants";
import { shallow } from "zustand/shallow";

import { useStore } from "../../store";
import { postMessage } from "../../utils";
import clx from "classnames";

export const CloseFrame = () => {
  const { frameState, setCloseModalState, loading, showApprovalBanner } =
    useStore(
      (state) => ({
        frameState: state.frameState,
        setCloseModalState: state.setCloseModalState,
        loading: state.loading,
        showApprovalBanner: state.showApprovalBanner,
      }),
      shallow
    );

  const onCloseFrame = () => {
    postMessage(CLOSE_FRAME);
  };

  const onClick = () => {
    if (frameState) onCloseFrame();
    else setCloseModalState(true);
  };
  if (loading) return null;
  return (
    <div
      data-testid="close-frame"
      id="close_frame"
      onClick={onClick}
      className={clx("close-frame", showApprovalBanner && "with-banner")}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_256_685)">
          <rect width="24" height="24" rx="12" fill="#F9F9F9" />
          <path
            d="M16 8L8 16M8 8L16 16"
            stroke="#333333"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_256_685">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
