import ContentLoader from "react-content-loader";
import React from "react";
import { useStore } from "../../store";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";

interface AppLogoSkeletonProps {
  wrapperCss: string;
}

export const AppLogoSkeleton: React.FC<AppLogoSkeletonProps> = ({
  wrapperCss = "",
}) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const width = isMobileApp ? "213.5" : "122";
  const viewBox = `0 0 ${width} 36`;
  const appTheme = useStore((state) => state.appTheme);

  const bgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG;
  const fgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG;
  return (
    <div className={wrapperCss}>
      <ContentLoader
        speed={2}
        width={width}
        height={36}
        viewBox={viewBox}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        <rect x="0" y="0" rx="6" ry="6" width={width} height="36" />
      </ContentLoader>
    </div>
  );
};
