import React from "react";
import { ellipseAddress, sanitizeMessage, isSolidityType } from "../../utils";
import { ELLIPSE_TYPE, ValueItem } from "../DetailsCollapse";

interface EIP712DataProps {
  data: Record<string, any>;
  valueCss?: string;
}
export const Eip712Data: React.FC<EIP712DataProps> = ({ data, valueCss }) => {
  const getEllipseTypeValue = (type: string) => {
    if (isSolidityType(type)) {
      // Truncate both bytes and address
      if (type.startsWith("bytes") || type.startsWith("address")) {
        return ELLIPSE_TYPE.ADDRESS;
      }
    }
    return ELLIPSE_TYPE.NONE;
  };

  const renderTree = (
    key: string | number,
    type: string,
    value: string | number | Record<string, any> | Record<string, any>[],
    indentLevel: number = 0
  ) => {
    const indentStyle = { paddingLeft: indentLevel > 0 ? "10px" : "0px" };

    const isObject = typeof value === "object" && value !== null;

    const isArray = Array.isArray(value);

    // We show array index starting from 1
    const keyRender = isNaN(key as unknown as number) ? (
      // We don't want to show ":" for the first level(primary type)
      <strong>{key + (indentLevel === 0 ? "" : ":")} </strong>
    ) : (
      <strong>{Number(key) + 1}: </strong>
    );

    if (isObject) {
      // Handle array type
      if (isArray) {
        return (
          <div>
            <div style={indentStyle}>
              {keyRender}
              {value.map((item, index) => (
                <div key={index}>
                  {renderTree(index, item.type, item.value, indentLevel + 1)}
                </div>
              ))}
            </div>
          </div>
        );
      }

      // Handle struct type
      return (
        <div style={indentStyle}>
          {keyRender}
          {Object.keys(value).map((key) => {
            return (
              <div key={key}>
                <div>
                  {renderTree(
                    key,
                    value[key].type,
                    value[key].value,
                    indentLevel + 1
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    // Handle primitive solidity types
    return (
      <div style={{ ...indentStyle, display: "flex" }}>
        {keyRender}
        <ValueItem
          value={String(value)}
          id={`value`}
          ellipseType={getEllipseTypeValue(type)}
          valueCss={valueCss}
        />
      </div>
    );
  };

  const sanitizedMessage = sanitizeMessage(
    data.message,
    data.primaryType,
    data.types
  );

  // show domain details at the top
  return (
    data.domain && (
      <div style={{ padding: "10px" }}>
        <div>
          {data?.domain?.name && (
            <div>
              <strong>Name:</strong> {data.domain.name}
            </div>
          )}
          {data?.domain?.version && (
            <div>
              <strong>Version:</strong> {data.domain.version}
            </div>
          )}
          {data?.domain?.verifyingContract && (
            <div>
              <strong>Contract:</strong>{" "}
              {ellipseAddress(data.domain.verifyingContract)}
            </div>
          )}
        </div>
        {/* Add a kind of separator */}
        <hr />
        <div>
          {renderTree(
            sanitizedMessage.type,
            sanitizedMessage.type,
            sanitizedMessage.value
          )}
        </div>
      </div>
    )
  );
};
