import { useStore } from "../../store";
import { shallow } from "zustand/shallow";

export const SecuredByFooter = () => {
  const loading = useStore((state) => state.loading, shallow);
  if (loading) return null;
  return (
    <div className="secured-by-footer">
      secured by <span>MetaKeep</span>
    </div>
  );
};
