import React from "react";
import ContentLoader from "react-content-loader";
import { useStore } from "../../store";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";

interface SkeletonProps {
  wrapperCss?: string;
}
export const InputSkeleton: React.FC<SkeletonProps> = ({ wrapperCss = "" }) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const width = isMobileApp ? "290" : "262";

  const viewBox = `0 0 ${width} 40`;
  const appTheme = useStore((state) => state.appTheme);

  const bgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG;
  const fgColor =
    appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG;
  return (
    <div className={wrapperCss}>
      <ContentLoader
        speed={2}
        width={width}
        height={40}
        viewBox={viewBox}
        backgroundColor={bgColor}
        foregroundColor={fgColor}
      >
        <rect x="0" y="0" rx="6" ry="6" width={width} height="39" />
      </ContentLoader>
    </div>
  );
};
