import { useMemo, useState } from "react";
import styles from "./index.module.scss";
import { useStore } from "../../store";
import { AppLogoSkeleton } from "../Skeleton";
export const AppLogo = () => {
  const logoSrc = useStore((state) => state.appLogoUrl);
  const loading = useStore((state) => state.loading);
  const [hasError, setError] = useState(false);
  const onError = () => setError(true);

  const imgChild = useMemo(
    () =>
      hasError || !logoSrc ? (
        <PlaceHolderImg />
      ) : (
        <img src={logoSrc} onError={onError} alt="" />
      ),
    [hasError, logoSrc]
  );
  if (loading)
    return (
      <div className={styles.frame_logo}>
        <AppLogoSkeleton />
      </div>
    );
  return <div className={styles.frame_logo}>{imgChild}</div>;
};

const PlaceHolderImg = () => <img src="/images/logo-placeholder.svg" alt="" />;
