import { useCallback, useEffect, useState } from "react";
import { MetakeepAuth } from "frontend-utils";

import { useStore } from "../../store";
import { CustomButton } from "../CustomButton";
import { CustomInput } from "../CustomInput";
import { TextSkeleton } from "../Skeleton";
import { validator } from "../../utils";
import { OTPField } from "../OTPField";
import clx from "classnames";
import styles from "./index.module.scss";
import { DARK_THEME } from "../../constants";

const ENTER_EMAIL = "ENTER_EMAIL";
const VERIFY_CODE = "VERIFY_CODE";

const DEFAULT_ERR_MSG = "Something went wrong";
const INVALID_CODE_MSG = "The code you've entered is incorrect";
const INVALID_RECOVERY_EMAIL =
  "Recovery email can't be the same as the primary email";
const INVALID_SESSION_TOKEN =
  "Verification failed. You may close this popup and try again";

const buildErrMessage = (errStr) => {
  if (!errStr || typeof errStr !== "string") return DEFAULT_ERR_MSG;
  if (errStr === "INVALID_CODE") return INVALID_CODE_MSG;
  if (errStr === "DUPLICATE_EMAIL") return INVALID_RECOVERY_EMAIL;
  if (
    errStr === "INVALID_TOKEN" ||
    errStr === "EXPIRED_TOKEN" ||
    errStr === "EXCEEDED_ATTEMPTS"
  )
    return INVALID_SESSION_TOKEN;
  return DEFAULT_ERR_MSG;
};

const UserRecoveryDetails = ({
  recoveryEmail,
  setRecoveryEmail,
  onSubmitEmail,
}) => {
  const [emailErr, setEmailErr] = useState(null);
  const setLoading = useStore((state) => state.setLoading);
  // handle email field change
  const onEmailChange = (e) => {
    setRecoveryEmail(e.target.value);
    setEmailErr(null);
  };

  const onButtonClick = async (e) => {
    e.preventDefault();

    const { email: emailErr } = validator("email", recoveryEmail);
    setEmailErr(emailErr);
    if (emailErr) return;
    try {
      setLoading(true);
      await onSubmitEmail();
    } catch (err) {
      const { status = null } = err?.response?.data || err;
      const errMsg = buildErrMessage(status);
      setEmailErr(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onButtonClick}>
      <div className="frame-copy">
        <RecoveryEmailCopy />
      </div>
      <CustomInput
        id="email"
        placeholder="Enter your recovery email address"
        value={recoveryEmail}
        type="email"
        onChangeHandler={onEmailChange}
        error={emailErr}
        data-testid="email-input"
        wrapperCss={
          emailErr === INVALID_RECOVERY_EMAIL &&
          styles.recovery_input_err_wrapper
        }
      />
      <div className="frame-cta">
        <CustomButton
          id="continue-btn"
          onClick={onButtonClick}
          className="primary-btn lg round"
          skeletonCss={styles.usr_btn_skeleton}
        >
          Add
        </CustomButton>
      </div>
    </form>
  );
};

const VerifyOTPCode = ({
  recoveryEmail,
  onVerifyOTPCode,
  endAuth,
  setEndAuth,
}) => {
  const [authErr, setAuthErr] = useState(null);
  const setLoading = useStore((state) => state.setLoading);

  const isMobileApp = useStore((state) => state.isMobileApp);
  const setSnackbarState = useStore((state) => state.setSnackbarState);
  const [otpCode, onSetCode] = useState(null);
  const toggleRecoveryEmailWizard = useStore(
    (state) => state.toggleRecoveryEmailWizard
  );
  const appTheme = useStore((state) => state.appTheme);
  const setAuthErrMsg = useCallback(
    (err) => {
      const errorMsg = buildErrMessage(err);
      if (errorMsg && errorMsg !== INVALID_CODE_MSG) setEndAuth(true);
      setAuthErr(errorMsg);
    },
    [setEndAuth]
  );

  const onVerify = useCallback(async () => {
    try {
      setLoading(true);
      await onVerifyOTPCode(otpCode);
      setLoading(false);
      setSnackbarState({
        open: true,
        message: "Recovery email added successfully",
        toastOpts: {
          autoClosein: 2000,
          hideProgress: true,
          hideBg: true,
          closeButton: false,
        },
      });
      toggleRecoveryEmailWizard(false);
    } catch (err) {
      const { status = null } = err?.response?.data || err;
      setAuthErrMsg(status);
    } finally {
      setLoading(false);
    }
  }, [
    onVerifyOTPCode,
    otpCode,
    setAuthErrMsg,
    setLoading,
    setSnackbarState,
    toggleRecoveryEmailWizard,
  ]);

  useEffect(() => {
    if (otpCode && otpCode.length === 6) onVerify();
  }, [onVerify, otpCode]);

  const canEnter = (value) => value.length <= 6;

  const handleCodeChange = useCallback((val) => {
    setAuthErr(null);
    if (canEnter(val)) onSetCode(val);
  }, []);

  return (
    <div
      className={clx(
        styles.auth_content,
        isMobileApp && styles.is_mobile_app,
        appTheme === DARK_THEME && styles.auth_content_dark_mode
      )}
    >
      <div
        className={clx(styles.auth_copy, isMobileApp && styles.is_mobile_app)}
      >
        <AuthCopy email={recoveryEmail} />
      </div>
      <OTPField
        isDisabled={endAuth}
        onChangeHandler={handleCodeChange}
        hasError={authErr}
      />
    </div>
  );
};

const AuthCopy = ({ email }) => {
  const loading = useStore((state) => state.loading);
  if (loading)
    return (
      <>
        <TextSkeleton />
        <TextSkeleton single wrapperCss={styles.single_text_skelly} />
      </>
    );
  return (
    <>
      For verification, we have sent a code to {email}
      <div className={styles.auth_sub}>Please enter it below</div>
    </>
  );
};

const RecoveryEmailCopy = () => {
  const { loading, appTheme } = useStore((state) => state);
  if (loading)
    return (
      <>
        <TextSkeleton single wrapperCss={styles.recovery_text_skelly_wrapper} />
        <TextSkeleton wrapperCss={styles.recovery_text_skelly_wrapper} />
      </>
    );
  return (
    <div
      className={clx(
        styles.auth_content,
        appTheme === DARK_THEME && styles.auth_content_dark_mode
      )}
    >
      Enable account recovery
      <div className={styles.recovery_sub_text}>
        If you lose access to your original email address, the verification
        codes will be sent to this alternative email address that you own.
      </div>
    </div>
  );
};

const Steps = {
  [ENTER_EMAIL]: UserRecoveryDetails,
  [VERIFY_CODE]: VerifyOTPCode,
};

export const RecoveryEmailWizard = () => {
  const [step, setStep] = useState(ENTER_EMAIL);
  const [recoveryEmail, setRecoveryEmail] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [endAuth, setEndAuth] = useState(false);
  const appId = useStore((state) => state.appId);
  const requesterDomain = useStore((state) => state.requesterDomain);
  const loading = useStore((state) => state.loading);
  const onSkipRecoveryEmail = useStore((state) => state.onSkipRecoveryEmail);

  const onSubmitEmail = async () => {
    const { updateAttributeRequestToken } = await MetakeepAuth.updateAttribute({
      appId,
      recoveryEmail,
      requesterDomain,
    });
    setSessionToken(updateAttributeRequestToken);

    setStep(VERIFY_CODE);
  };

  const onVerifyOTPCode = useCallback(
    async (otpCode) => {
      await MetakeepAuth.updateAttribute({
        updateAttributeRequestToken: sessionToken,
        otpCode,
        requesterDomain,
      });
    },
    [sessionToken, requesterDomain]
  );

  const CurrentChild = Steps[step];
  return (
    <>
      <CurrentChild
        onProceed={setStep}
        recoveryEmail={recoveryEmail}
        setRecoveryEmail={setRecoveryEmail}
        onSubmitEmail={onSubmitEmail}
        onVerifyOTPCode={onVerifyOTPCode}
        endAuth={endAuth}
        setEndAuth={setEndAuth}
      />
      {!loading && (
        <div
          onClick={() => onSkipRecoveryEmail(endAuth)}
          className={styles.skip_recovery}
        >
          {endAuth ? "Continue" : "Skip for now"}
        </div>
      )}
    </>
  );
};
