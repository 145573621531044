import { Button } from "reactstrap";
import styled from "styled-components";
import { useStore } from "../../store";
import { CTASkeleton } from "../Skeleton";

import { getButtonBg } from "../../utils";
import { BUTTON_CSS_SELECTORS, addImportant } from "../../utils/cssUtils";
import {
  DEFAULT_DARK_THEME_BG_COLOR,
  DEFAULT_LIGHT_THEME_BG_COLOR,
  LIGHT_THEME,
} from "../../constants";

const getTransparentButtonCss = (props) => {
  const buttonProps = props.$buttonCssProps || {};
  const buttonHoverProps = props.$buttonHoverCssProps || {};
  const outlinedBtnProps = props.$outlinedBtnCssProps || {};
  const outlinedBtnHoverProps = props.$outlinedBtnHoverCssProps || {};
  const appTheme = props.$appTheme || LIGHT_THEME;
  const defaultColor =
    appTheme === LIGHT_THEME
      ? DEFAULT_LIGHT_THEME_BG_COLOR
      : DEFAULT_DARK_THEME_BG_COLOR;
  // for transparent buttons with linear gradient background
  // we cannot directly set border to linear gradient
  // this uses a before pseduo element to achieve the same
  if (buttonProps?.background?.includes("linear-gradient")) {
    const textColor = outlinedBtnProps?.color || buttonProps?.color;
    const hoverTextColor =
      outlinedBtnHoverProps?.color || buttonHoverProps?.color;
    return {
      ...buttonProps,
      background: "transparent !important",
      position: "relative !important",
      border: "none !important",
      color: textColor,
      "&::before": {
        content: "' '",
        background: buttonProps.background,
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        "-webkit-mask-composite": "xor",
        "-webkit-mask":
          "linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);",
        padding: "2px",
        "border-radius": "inherit",
        "mask-composite": "exclude", // for firefox
      },
      "&:hover": {
        "&::before": {
          background: buttonHoverProps?.background,
        },
        color: hoverTextColor,
      },
    };
  }
  const borderColor =
    buttonProps["border-color"] ||
    buttonProps["background"] ||
    buttonProps["background-color"];
  const textColor =
    outlinedBtnProps["color"] ||
    buttonProps["color"] ||
    buttonProps["background"] ||
    buttonProps["background-color"] ||
    addImportant(defaultColor);
  const hoverBorderColor =
    buttonHoverProps["border-color"] ||
    buttonHoverProps["background"] ||
    buttonHoverProps["background-color"];
  const hoverTextColor =
    outlinedBtnHoverProps["color"] ||
    outlinedBtnProps["color"] ||
    buttonHoverProps["color"] ||
    buttonHoverProps["background"] ||
    buttonHoverProps["background-color"];
  return {
    ...buttonProps,
    "border-style": "solid !important",
    "border-width": "1px !important",
    ...(borderColor ? { "border-color": `${borderColor}` } : {}),
    ...(textColor ? { color: `${textColor}` } : {}),
    "&:hover": {
      ...buttonHoverProps,
      background: "transparent !important",
      ...(hoverBorderColor ? { "border-color": `${hoverBorderColor}` } : {}),
      ...(hoverTextColor ? { color: `${hoverTextColor}` } : {}),
    },
  };
};

export const CustomButton = (props) => {
  const {
    secondaryColor,
    loading,
    appTheme,
    customCss = {},
  } = useStore((state) => state);
  const { children, skeletonCss, ...rest } = props;

  if (loading) return <CTASkeleton wrapperCss={skeletonCss} />;

  const btnBg = getButtonBg(appTheme, secondaryColor);

  const hasCustomButtonCSS =
    Object.keys(customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN] || {}).length > 0 ||
    Object.keys(customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_HOVER] || {})
      .length > 0 ||
    Object.keys(customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_OUTLINED] || {})
      .length > 0 ||
    Object.keys(
      customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_OUTLINED_HOVER] || {}
    ).length > 0;
  if (hasCustomButtonCSS)
    return (
      <StyledButton
        {...rest}
        $appTheme={appTheme}
        $buttonCssProps={customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN]}
        $buttonHoverCssProps={customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_HOVER]}
        $outlinedBtnCssProps={
          customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_OUTLINED]
        }
        $outlinedBtnHoverCssProps={
          customCss[BUTTON_CSS_SELECTORS.PRIMARY_BTN_OUTLINED_HOVER]
        }
      >
        {children}
      </StyledButton>
    );

  return (
    <Button
      {...rest}
      style={{ background: btnBg, ...(rest?.style ? rest.style : {}) }}
    >
      {children}
    </Button>
  );
};

const StyledButton = styled.button((props) => {
  const className = props.className || "";
  // for transparent buttons we need to apply the border as the primary background
  // so we need to specially compute this seperately
  if (className.includes("clr-transp")) {
    const transparentButtonCss = getTransparentButtonCss(props);
    return transparentButtonCss;
  }
  return {
    ...props.$buttonCssProps,
    "&:hover": {
      ...props.$buttonHoverCssProps,
    },
  };
});
