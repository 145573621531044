"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSessionKeys = void 0;
const frontend_utils_1 = require("frontend-utils");
const store_1 = require("../../../store");
const __1 = require("..");
const constants_1 = require("../../../constants");
const sessionKeyUtils_1 = require("frontend-utils/sessionKeyUtils");
const logger_1 = require("frontend-utils/logger");
/**
 * Custom hook to manage session keys generation and storage.
 * @param {Options} options - Options object.
 * @returns {Object} Object containing functions to generate keys and save them to session storage.
 */
const useSessionKeys = ({ setCurrentScreen }) => {
    const usePasskey = (0, store_1.useStore)((state) => state.usePasskey);
    /**
     * Handles the generation of a passkey.
     * @returns {Promise<KeyPair|null>} Promise resolving to the generated passkey object or null if failed.
     */
    const handleGeneratePasskey = async () => {
        const currentParsedUser = store_1.useStore.getState().user;
        const getLastUsedWebauthnKey = async (parsedUser) => await frontend_utils_1.SessionKeysUtils.getLastUsedWebauthnKey({
            parsedUser,
            keyPrefix: constants_1.METAKEEP_IDENTITY_PROVIDER_PREFIX,
        });
        try {
            const webauthnKey = (await frontend_utils_1.PasskeyUtils.createKeyPair({
                parsedUser: currentParsedUser,
                reuseWebauthnKeyIfExists: await getLastUsedWebauthnKey(currentParsedUser),
            }));
            return Object.assign(Object.assign({}, webauthnKey), { keyType: sessionKeyUtils_1.SESSION_KEY_TYPES.WEBAUTHN });
        }
        catch (error) {
            logger_1.logger.error("Failed to generate passkey:", error);
            return undefined;
        }
    };
    /**
     * Handles the generation of a crypto key.
     * @returns {Promise<KeyPair|null>} Promise resolving to the generated crypto key object or null if failed.
     */
    const handleGenerateCryptoKey = async () => {
        try {
            const cryptoKey = (await frontend_utils_1.CryptoUtils.generateKeyPair(false));
            return Object.assign(Object.assign({}, cryptoKey), { keyType: sessionKeyUtils_1.SESSION_KEY_TYPES.CRYPTO });
        }
        catch (error) {
            logger_1.logger.error("Failed to generate crypto key:", error);
            return undefined;
        }
    };
    /**
     * Generates session keys based on availability of WebAuthn.
     * @param {GenerateKeysOptions} options - Options object.
     */
    const generateKeys = async ({ onSuccess = () => { }, onError = () => { }, }) => {
        const isWebAuthnAvailable = (usePasskey || constants_1.USE_PASSKEY) &&
            (await frontend_utils_1.PasskeyUtils.isWebAuthnAuthenticatorAvailable());
        try {
            let newKeyPairs = [];
            if (isWebAuthnAvailable) {
                logger_1.logger.debug("WebAuthn authenticator is available, trying to generate passkey.");
                setCurrentScreen(__1.SCREEN_NAMES.PASSKEY);
                const passkeyPromise = await handleGeneratePasskey();
                setCurrentScreen(__1.SCREEN_NAMES.OTP);
                const cryptoKeyPromise = await handleGenerateCryptoKey();
                const [passkeyKeys, cryptoKeys] = await Promise.all([
                    passkeyPromise,
                    cryptoKeyPromise,
                ]);
                newKeyPairs = [passkeyKeys, cryptoKeys].filter((key) => key !== undefined);
            }
            else {
                const cryptoKey = await handleGenerateCryptoKey();
                if (cryptoKey) {
                    newKeyPairs.push(cryptoKey);
                }
            }
            onSuccess(newKeyPairs);
        }
        catch (error) {
            console.error("Failed to generate keys:", error);
            onError();
        }
    };
    /**
     * Saves generated key pairs to session storage.
     * @param {SaveToSessionStorageOptions} options - Options object.
     */
    const saveKeysToSessionStorage = async ({ keyPairs, sessionId, }) => {
        const currentParsedUser = store_1.useStore.getState().user;
        await frontend_utils_1.SessionKeysUtils.storeKeysToDb({
            keyPrefix: constants_1.METAKEEP_IDENTITY_PROVIDER_PREFIX,
            parsedUser: currentParsedUser,
            sessionId,
            sessionKeys: keyPairs,
        });
    };
    return { generateKeys, saveKeysToSessionStorage };
};
exports.useSessionKeys = useSessionKeys;
