import React from "react";
import { CheckSVG } from "./CheckSVG";
import { CrossSVG } from "./CrossSVG";
import { useStore } from "../../store";
import {
  CLOSE_FRAME,
  CONSENT_MESSAGE_MAP,
  ERROR,
  INVALID_REQUEST,
  SUCCESS,
} from "../../constants";
import { postMessage, getButtonBg } from "../../utils";
import { CustomButton } from "../CustomButton";

export const CompletedState: React.FC = () => {
  const frameState = useStore((state) => state.frameState);
  const isMobile = useStore((state) => state.isMobileApp);
  const msg = CONSENT_MESSAGE_MAP[frameState?.state ?? INVALID_REQUEST];
  const secondaryColor = useStore((state) => state.secondaryColor);
  const appTheme = useStore((state) => state.appTheme);
  const btnBg = getButtonBg(appTheme!, secondaryColor!);
  const isSuccess = Boolean(frameState?.isSuccess);

  const onClick = () => {
    // UI flows are different on the Web and Mobile
    //
    // On the Web, after the user operation has completed, a message will be sent to the parent window with JS postMessage API. This API always succeeds.
    // If `skip_final_dialog` is set to true for the app, the parent window will not show the final dialog and will close the popup immediately.
    // If `skip_final_dialog` is set to false for the app, the user will see the final dialog and the user can click the `Done` button to close the popup.
    // On clicking `Done`, a CLOSE_FRAME event is sent to the parent window to close the popup.
    //
    // On Mobile, after the user operation has completed, a callback is triggered by changing the URL of the current page which should redirect the user back to the app.
    // The `skip_final_dialog` parameter is not applicable on mobile.
    // In some cases, the platform might block the redirect and show a dialog to the user to click `Open` to go back to the app. In this case, the user will see the final dialog and
    // the user can click the `Done` button to go back to the app which again triggers the callback by changing the URL of the current page.
    if (isMobile) {
      if (!frameState?.frameResponse) return;
      postMessage(isSuccess ? SUCCESS : ERROR, frameState?.frameResponse);
      return;
    }
    postMessage(CLOSE_FRAME);
  };

  return (
    <div className="completed-state">
      {isSuccess ? (
        <div className="check-svg-container">
          <CheckSVG />
        </div>
      ) : (
        <div className="cross-svg-container">
          <CrossSVG />
        </div>
      )}
      <div className="completed-state-copy">{msg}</div>
      <div className="completed-state-cta">
        <CustomButton
          style={{ background: btnBg }}
          onClick={onClick}
          className="primary-btn round lg"
          id="completed_state_cta"
        >
          {isSuccess ? "Done" : "Close"}
        </CustomButton>
      </div>
    </div>
  );
};
